import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Dimension } from './dimension.model'
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class DimensionService extends EntityCollectionServiceBase<Dimension> {
	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Dimension', serviceElementsFactory)
	}

	private dimensionsForDimensionLevel = new Map<
		number,
		Dimension[] | undefined
	>()

	getParentDimensionsByDimensionLevelId(
		dimensionLevelId: number | undefined
	): Observable<Dimension[] | undefined> {
		if (!dimensionLevelId) return of(undefined)

		if (this.dimensionsForDimensionLevel.has(dimensionLevelId)) {
			return of(this.dimensionsForDimensionLevel.get(dimensionLevelId)!)
		} else {
			const result$ = this.http.get<Dimension[]>(
				'dimensions/getParentDimensionsForDimensionLevel/' + dimensionLevelId
			)

			result$.subscribe((dimensions) =>
				this.dimensionsForDimensionLevel.set(dimensionLevelId, dimensions)
			)

			return result$
		}
	}

	/**
	 * Add dimension to dimensions on given dimension level
	 * @param dimensionLevelId Dimension level
	 * @param dimension Dimension
	 */
	addDimensionsForDimensionLevel(
		dimensionLevelId: number | undefined,
		dimension: Dimension
	) {
		if (dimensionLevelId !== undefined) {
			let dimensions = this.dimensionsForDimensionLevel.get(dimensionLevelId)
			if (dimensions === undefined) dimensions = []

			dimensions.push(dimension)
			this.dimensionsForDimensionLevel.set(dimensionLevelId, dimensions)
		}
	}
}
