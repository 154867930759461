import { ChangeDetectionStrategy, Component, signal } from '@angular/core'
import {
	ModalBodyComponent,
	ModalComponent,
} from 'src/app/shared/components/modal/modal.component'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import { emptyRow } from 'src/app/shared/components/form/formly-field'
import { WorktimeGroupHistory } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history.model'
import {
	WorktimeGroupService,
	WorktimeGroupHistoryService,
	PersonService,
	ToastService,
} from 'src/app/core/ngrx-store/entity-services'
import { first, map, Observable, of } from 'rxjs'
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap'
import { selectWorktimeGroupHistoryForPerson } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history.selectors'
import { Store } from '@ngrx/store'
import { WorktimeGroupHistoryToPast } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history-mapping.model'
import { CommonModule } from '@angular/common'
import { DateUtils } from 'src/app/core/utils/date-utils'
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component'

@Component({
	selector: 'kk-worktime-group-history-modal',
	imports: [
		CommonModule,
		ModalComponent,
		ModalBodyComponent,
		FormlyModule,
		ReactiveFormsModule,
		FormlyBootstrapModule,
		LoadingComponent,
	],
	providers: [WorktimeGroupService, WorktimeGroupHistoryService],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<kk-modal title="Työaikaryhmähistoria" i18n-title>
			<kk-loading-spinner [visible]="loading()" />

			<kk-modal-body>
				<form [formGroup]="form" data-testid="ManagementCreateNewForm">
					<formly-form
						*ngIf="!toPast()"
						data-testid="worktime-group-history-modal-form"
						[form]="form"
						[fields]="fields"
						[model]="model"
					/>
					<formly-form
						*ngIf="toPast()"
						data-testid="worktime-group-history-to-past-modal-form"
						[form]="form"
						[fields]="toPastFields"
						[model]="toPastModel"
					/>
				</form>
			</kk-modal-body>
		</kk-modal>
	`,
})
export class WorktimeGroupHistoryComponent {
	worktimeGroupHistory$ = this.store.select(selectWorktimeGroupHistoryForPerson)

	loading = signal(false)
	toPast = signal<boolean>(false)
	selectedWorktimeGroupName = signal<string>('')

	constructor(
		private worktimeGroupService: WorktimeGroupService,
		private worktimeGroupHistoryService: WorktimeGroupHistoryService,
		private personService: PersonService,
		private store: Store,
		private toastService: ToastService
	) {}

	onAddClick() {
		if (
			this.model.groupId !== undefined &&
			this.model.startTime !== undefined &&
			this.model.personId !== undefined
		) {
			const currentDate = new Date()
			const selectedDate = new Date(this.model.startTime)

			// Check if selected date is in the past
			if (selectedDate.getTime() <= currentDate.getTime()) {
				// Get worktime group history change to past -data
				this.worktimeGroupHistoryService
					.worktimeGroupHistoryToPast(
						this.model.personId,
						this.model.groupId,
						selectedDate
					)
					.subscribe((result) => {
						// If there are workshifts or event types to be changed, show modal
						if (
							result.eventTypeSelections.length > 0 ||
							result.workShiftSelections.length > 0
						) {
							this.toPastModel = result
							this.toPast.set(true)
						} else {
							// If there are no changes, submit the form
							this.onSubmit()
						}
					})
			} else this.onSubmit()
		}
	}

	onSubmit() {
		this.loading.set(true)
		this.worktimeGroupHistoryService
			.add({ ...this.model }, { isOptimistic: false })
			.subscribe(() => {
				this.personService.getByKey(this.model.personId)
				this.loading.set(false)
			})
	}

	cancelChangeToPast() {
		this.toPast.set(false)
	}

	/**
	 * Commit changes to past
	 */
	commitChangeToPast() {
		if (this.toPastModel) this.loading.set(true)
		this.worktimeGroupHistoryService
			.setWorktimeGroupHistoryToPast(this.toPastModel)
			.subscribe(() => {
				this.personService.getByKey(this.model.personId)
				this.toPast.set(false)
				this.toastService.showSuccess(
					$localize`Työaikaryhmän vaihdos lisätty päivämäärälle` +
						' ' +
						DateUtils.formatStringTocalString(
							this.toPastModel.startTime,
							'd.M.yyyy'
						)
				)
				this.loading.set(false)
			})
	}

	model: Partial<WorktimeGroupHistory> = {
		groupId: undefined,
		startTime: undefined,
		personId: undefined,
	}

	form = new FormGroup({})
	fields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'grid gap-0 column-gap-sm-3',
			fieldGroup: [
				{
					key: 'groupId',
					type: 'select',
					className: 'g-col-12',
					props: {
						required: true,
						label: $localize`Työaikaryhmä`,
						options: this.worktimeGroupService.entities$.pipe(
							map((worktimeGroups) =>
								worktimeGroups.map((group) => ({
									value: group.id,
									label: group.name,
								}))
							)
						),
						attributes: {
							'data-testid': 'worktime-group-history-modal-group',
						},
					},
				},
				{
					key: 'startTime',
					type: 'datetime',
					className: 'g-col-12',
					props: {
						required: true,
						disabled: false,
						label: $localize`Alkupäivä`,
						controls: ['date'],
						dateFormat: 'D.M.YYYY',
						returnFormat: 'iso8601',
						attributes: {
							'data-testid': 'worktime-group-history-modal-start-time',
						},
					},
				},
				{
					type: 'button',
					className: 'g-col-5',
					props: {
						label: 'Lisää',
						disabled: this.loading(),
						onClick: () => this.onAddClick(),
						className: 'btn btn-success',
						attributes: {
							'data-testid': 'worktime-group-history-modal-submit',
						},
					},
				},
				emptyRow,
				{
					type: 'worktime-group-history-table',
					className: 'g-col-12',
					props: {
						items: this.worktimeGroupHistory$,
					},
				},
				emptyRow,
			],
			hooks: {
				onInit: () => {
					if (this.model.personId !== undefined) {
						this.worktimeGroupHistoryService.loadWithQuery({
							personId: this.model.personId,
						})
					}
				},
			},
		},
	]

	toPastModel: Partial<WorktimeGroupHistoryToPast> = {}
	toPastFields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'grid gap-0 column-gap-sm-3',
			fieldGroup: [
				{
					type: 'tabs',
					className: 'g-col-12',
					fieldGroup: [
						{
							props: { label: $localize`Työvuorot` },
							fieldGroupClassName: 'full-width',
							fieldGroup: [
								{
									type: 'worktime-group-change-to-past-workshifts',
									props: {
										model: this.toPastModel,
									},
									hooks: {
										onInit: (field) => {
											field.props!['items'] =
												this.toPastModel.workShiftSelections
											field.props!['newGroupId'] =
												this.toPastModel.newWorktimeGroupId
										},
									},
								},
							],
						},
						{
							props: { label: $localize`Kirjauslajit` },
							fieldGroupClassName: 'full-width',
							fieldGroup: [
								{
									type: 'worktime-group-change-to-past-event-types',
									hooks: {
										onInit: (field) => {
											field.props!['items'] =
												this.toPastModel.eventTypeSelections
											field.props!['newGroupId'] =
												this.toPastModel.newWorktimeGroupId
										},
									},
								},
							],
						},
					],
				},

				{
					type: 'button',
					className: 'g-col-5',
					props: {
						label: 'Peruuta',
						disabled: this.loading(),
						onClick: () => this.cancelChangeToPast(),
						className: 'btn btn-danger',
					},
				},

				{
					type: 'button',
					className: 'g-col-5',
					props: {
						label: 'Ok',
						disabled: this.loading(),
						onClick: () => this.commitChangeToPast(),
						className: 'btn btn-success',
					},
				},
			],
		},
	]
}
