import { EntityService, EntityType, entitySelectorFactory } from '../../models'
import { InjectionToken, inject, Injectable } from '@angular/core'
import {
	EventTypeService,
	WorktimeGroupService,
	WorktimeReasonService,
} from 'src/app/core/ngrx-store/entity-services'
import {
	eventTypeSelector,
	selectEventTypeId,
} from 'src/app/core/ngrx-store/event-type/event-type.selectors'
import { map, of } from 'rxjs'
import {
	flexHandlingLabels,
	usableOnHourDayEventLables,
	eventTypeCategoryLabels,
	EventType,
} from 'src/app/core/ngrx-store/event-type/event-type.model'
import { EventTypeCreateNewContentComponent } from './event-type-create-new.component'
import {
	numberField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'
import { WorktimeGroup } from '../../../../core/ngrx-store/models'
import { UsableOnHourDayEvent } from '../../../../core/ngrx-store/event-type/event-type.model'

export const selectEventTypeEntity = entitySelectorFactory(eventTypeSelector)

@Injectable({ providedIn: 'root' })
export class EventTypeEntityService extends EntityService<EventType> {
	override mapEntityToSidebarItem = (eventType: EventType) => {
		return {
			id: selectEventTypeId(eventType).toString(),
			title: of(eventType.name),
			subtitle: of(
				$localize`Tyyppi: ` +
					eventTypeCategoryLabels[eventType.eventTypeCategory]
			),
		}
	}

	constructor(
		eventTypeService: EventTypeService,
		private readonly worktimeReasonService: WorktimeReasonService,
		private readonly worktimeGroupService: WorktimeGroupService
	) {
		super(eventTypeService, selectEventTypeEntity)
		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Perustiedot` },
						fieldGroupClassName: 'grid align-items-end',
						fieldGroup: [
							textField('name', $localize`Nimi`, 'g-col-12'),
							{
								key: 'eventTypeCategory',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Kirjauslajityyppi`,
									options: Object.entries(eventTypeCategoryLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-event-type-category',
									},
								},
							},
							{
								key: 'usableOnHourDayEventSelection',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Käytössä tuntikirjauksella`,
									options: Object.entries(usableOnHourDayEventLables).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid':
											'management-event-type-usableOnHourDayEventSelection',
									},
								},
							},
							{
								key: 'flexHandling',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Liukumakäsittely`,
									options: Object.entries(flexHandlingLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-event-type-flexHandling',
									},
								},
							},
							{
								key: 'ignoreStartEndTimesLimit',
								type: 'checkbox',
								className: 'g-col-12',
								props: {
									label: $localize`Ei noudata liukuma-kellonaikarajoituksia`,
								},
							},
							{
								key: 'worktimeGroups',
								type: 'multiselect',
								className: 'g-col-12',
								props: {
									label: $localize`Rajaa valituille työaikaryhmille`,
									multiple: true,
									items: this.worktimeGroupService.entities$.pipe(
										map((worktimeGroups: WorktimeGroup[]) =>
											worktimeGroups.map((group) => ({
												value: group.id,
												label: group.name,
											}))
										)
									),
									attributes: {
										'data-testid': 'management-event-type-worktimeGroups',
									},
								},
							},
							{
								key: 'color',
								type: 'color',
								className: 'g-col-12',
								props: {
									label: $localize`Väri kalenterissa`,
									attributes: {
										'data-testid': 'management-event-type-color',
									},
								},
							},
							{
								key: 'useBackgroundColor',
								type: 'checkbox',
								className: 'g-col-12',
								props: {
									label: $localize`Taustaväri käytössä kirjaukselle`,
									attributes: {
										'data-testid': 'management-event-type-useBackgroundColor',
									},
								},
							},
							numberField('orderNumber', $localize`Näyttöjärjestys`),
						],
					},
					{
						props: { label: $localize`Syykoodit` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'eventTypesToWorktimeReasons',
								type: 'event-type-worktime-reason-mapping',
								className: 'g-col-12',
								expressions: {
									hide: (field) =>
										field.model.usableOnHourDayEventSelection ===
										UsableOnHourDayEvent.UsableOnDayEvent,
								},
							},
						],
					},
				],
				hooks: {
					onInit: () => {
						this.worktimeReasonService.getAll()
					},
				},
			},
		]
	}
}

export const eventTypeEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Worktime,
	title: $localize`Kirjauslajit`,
	explanation: $localize`Kaikki työaika kirjataan kirjauslajeille
	Valitse sivupalkista tarkasteltava tai muokattava kirjauslaji`,
	path: 'event-types',
	serviceToken: new InjectionToken<EventTypeEntityService>('event-types', {
		factory: () => inject(EventTypeEntityService),
	}),
	createNewContentComponent: EventTypeCreateNewContentComponent,
}
