import { Injectable } from '@angular/core'
import { Dimension, DimensionSelectionType } from './dimension.model'
import { PaginationDataService } from '../paginated-data.service'
import { HttpClient } from '@angular/common/http'
import { HttpUrlGenerator } from '@ngrx/data'
import { Observable, map } from 'rxjs'

@Injectable()
export class DimensionDataService extends PaginationDataService<Dimension> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('Dimension', http, httpUrlGenerator)
	}

	private dimensionSelectionType = DimensionSelectionType.Event

	activateDimension(id: number): Observable<number[]> {
		return super
			.execute('POST', this.entitiesUrl + `activateDimension?id=${id}`)
			.pipe(map((result: unknown) => result as number[]))
	}

	deactivateDimension(id: number): Observable<number[]> {
		return super
			.execute('POST', this.entitiesUrl + `deactivateDimension?id=${id}`)
			.pipe(map((result: unknown) => result as number[]))
	}

	override getAll(): Observable<Dimension[]> {
		return this.getAllPages()
	}

	setSource(type: DimensionSelectionType) {
		this.dimensionSelectionType = type
	}

	getSource() {
		return this.dimensionSelectionType
	}
}
