import { NgModule } from '@angular/core'
import { EntityDataModule, EntityDataService } from '@ngrx/data'
import {
	ViewCustomizationService,
	UserService,
	CardWidgetService,
	ListWidgetService,
	NewsService,
	EnvironmentService,
	AvailableCardWidgetService,
	AvailableListWidgetService,
	WorktimeGroupService,
	WorktimeGroupHistoryService,
	PersonService,
	PersonPublicDataService,
	ProjectManagerService,
	WorktimeDayService,
	WorktimeDayInfoService,
	WorktimeWeekService,
	EventService,
	StampHandlerService,
	EventTypeService,
	PersonAttributeService,
	WorkShiftService,
	RealizedWorkShiftService,
	DimensionService,
	DimensionLevelService,
	StampingReasonService,
	PersonTemplateService,
	HolidaysService,
	ExceptionDaysService,
	CompanyLocationsService,
	SupervisorService,
	ToastService,
	TimeCounterBalanceService,
	SelectedDimensionService,
	AbsenceService,
	WorktimeReasonService,
	BalanceLockService,
} from './entity-services'
import { entityConfig } from './entity-metadata'
import { NewsDataService } from './news/news.data-service'
import {
	PersonDataService,
	PersonTemplateDataService,
} from './person/person.data-service'
import { WorktimeGroupDataService } from './worktime-group/worktime-group-data.service'
import { StoreModule } from '@ngrx/store'
import { userFeature } from './user/user.feature'
import { WorkShiftDataService } from './workshift/workshift.data-service'
import { DimensionDataService } from './dimension/dimension.data-service'
import { StampService } from './stamp/stamp.service'
import { toastFeature } from './toast/toast.feature'
import { BalanceLockDataService } from './balance-lock/balance-lock.data-service'

@NgModule({
	imports: [
		StoreModule.forFeature(userFeature),
		StoreModule.forFeature(toastFeature),
		EntityDataModule.forRoot(entityConfig),
	],
	providers: [
		ViewCustomizationService,
		UserService,
		CardWidgetService,
		ListWidgetService,
		AvailableCardWidgetService,
		AvailableListWidgetService,
		BalanceLockDataService,
		BalanceLockService,
		NewsService,
		DimensionDataService,
		EnvironmentService,
		NewsDataService,
		PersonService,
		PersonPublicDataService,
		ProjectManagerService,
		WorktimeDayService,
		WorktimeDayInfoService,
		WorktimeWeekService,
		PersonDataService,
		PersonTemplateService,
		PersonTemplateDataService,
		PersonAttributeService,
		WorktimeGroupService,
		WorktimeGroupHistoryService,
		WorktimeGroupDataService,
		EventService,
		StampHandlerService,
		EventTypeService,
		WorktimeReasonService,
		WorkShiftService,
		RealizedWorkShiftService,
		WorkShiftDataService,
		DimensionService,
		DimensionLevelService,
		SelectedDimensionService,
		StampingReasonService,
		HolidaysService,
		ExceptionDaysService,
		CompanyLocationsService,
		SupervisorService,
		StampService,
		ToastService,
		TimeCounterBalanceService,
		AbsenceService,
	],
})
export class EntityStoreModule {
	constructor(
		entityDataService: EntityDataService,
		balanceLockDataService: BalanceLockDataService,
		newsDataService: NewsDataService,
		personDataService: PersonDataService,
		personTemplateDataService: PersonTemplateDataService,
		worktimeGroupDataService: WorktimeGroupDataService,
		workShiftDataService: WorkShiftDataService
	) {
		entityDataService.registerService('News', newsDataService)
		entityDataService.registerService('Person', personDataService)
		entityDataService.registerService(
			'PersonTemplate',
			personTemplateDataService
		)
		entityDataService.registerService('BalanceLock', balanceLockDataService)
		entityDataService.registerService('WorktimeGroup', worktimeGroupDataService)
		entityDataService.registerService('WorkShift', workShiftDataService)
	}
}
